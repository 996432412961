import React, { useState, useEffect } from "react";
import { TitleSection } from "../../components/titleSection";
import { Table } from "../../components/table";
import { CalendarRange, CalendarWithEnabledRange } from "../../components/myCalendar";
import {
    FlexboxGrid,
    Col,
    Form,
    FormGroup,
    FormControl,
    ControlLabel,
    Input,
    InputGroup,
    Icon,
    Button,
    Pagination,
    CheckboxGroup,
    Checkbox,
} from "rsuite";
import { transactionService } from "../../services/transaction.service";
import { chargebackService } from "../../services/chargeback.service";
import { useSelector } from "react-redux";
import { getColumns } from "../../hooks/templateColumnsChargebacks";
import statusJson from "../../data/status.json";
import { StatusEnum } from "../../enum/statusEnum";
import "./chargebacks.scss";

export const Chargebacks = (props) => {
    const userSetting = useSelector((state) => state.userSetting);
    const [status, setStatus] = useState([]);
    const [statusChargeBack, setStatusChargeBack] = useState([{ idStatusChargeBack: 0, name: '', description: '' }]);
    const [data, setData] = useState([]);
    const [isChargeBack, setIsChargeBack] = useState(false);
    const [fechasApi, setFechasApi] = useState({ initDate: undefined, endDate: undefined });
    const initialFilters = {
        idStatus: 17,
        initDate: undefined,
        endDate: undefined,
        referenceNumber: "",
        email: "",
        statusChargeBack: undefined
    };

    const [filters, setFilters] = useState(() => {
        if (sessionStorage["rp_filters_ChargeBack"] !== undefined) {
            let filtersTmp = JSON.parse(sessionStorage["rp_filters_ChargeBack"]);
            filtersTmp.initDate = new Date(filtersTmp.initDate);
            filtersTmp.endDate = new Date(filtersTmp.endDate);
            return filtersTmp;
        }
        return initialFilters;
    });

    const initialTableData = { rows: 0, pages: 0, activePage: 1 };
    const setInitialTableData = () => {
        let tableDataTmp = initialTableData;
        if (
            sessionStorage["rp_ActivePage_ChargeBack"] !== undefined
        )
            tableDataTmp.activePage = parseInt(sessionStorage["rp_ActivePage_ChargeBack"]);

        return tableDataTmp;
    };
    const [tableData, setTableData] = useState(setInitialTableData());

    useEffect(() => {
        const fetchDate = async () => {
            const fechas = await chargebackService.getDate();
            setFechasApi(prevFechas => ({ ...prevFechas, endDate: new Date(fechas.fechaFinal), initDate: new Date(fechas.fechaInicial) }));
        };
        fetchDate();

        return () => {
            sessionStorage.removeItem('rp_filters_ChargeBack');
            sessionStorage.removeItem('rp_ActivePage_ChargeBack');
        }
    }, []);

    const fetchStatusChargeBack = async (isSeFilter = 1) => {
        const result = await transactionService.statusChargeBack();
        if (result.length > 0) {
            let idStatusCB = result.map(x => x.idStatusChargeBack);
            setStatusChargeBack(result);
            if (isSeFilter == 1)
                setFilters(prevFilters => ({ ...prevFilters, statusChargeBack: idStatusCB }));
            else {
                let filterSession = JSON.parse(sessionStorage.getItem('rp_filters_ChargeBack'));
                setFilters(prevFilters => {
                    let newFilters = { ...prevFilters };
                    newFilters.endDate = new Date(filterSession.endDate);
                    newFilters.initDate = new Date(filterSession.endDate);
                    newFilters.idStatus = filterSession.idStatus;
                    newFilters.referenceNumber = filterSession.referenceNumber;
                    newFilters.email = filterSession.email;
                    newFilters.statusChargeBack = filterSession.statusChargeBack;
                    return newFilters;
                });
                var pageNo = parseInt(sessionStorage.getItem('rp_ActivePage_ChargeBack') ?? 1);
                getTransactions(pageNo);
            }
        }
    };

    useEffect(() => {
        if (fechasApi.initDate && fechasApi.endDate) {
            if (sessionStorage.getItem("rp_filters_ChargeBack"))
                fetchStatusChargeBack(0);
            else
                setFilters(prevFilters => {
                    let newFilters = { ...prevFilters };
                    newFilters.endDate = fechasApi.endDate;
                    newFilters.initDate = fechasApi.endDate;
                    return newFilters;
                });
            fetchStatusChargeBack();
        }
    }, [fechasApi]);

    useEffect(() => {
        if (filters.statusChargeBack && filters.initDate && filters.endDate)
            getTransactions(tableData.activePage);
    }, [filters]);

    const getTransactions = (pageNumber) => {
        if (filters.initDate == "Invalid Date" || filters.endDate == "Invalid Date") {
            filters.initDate = filters.initDate == "Invalid Date" ? "" : filters.initDate;
            filters.endDate = filters.endDate == "Invalid Date" ? "" : filters.endDate;
        }

        chargebackService
            .get({ ...filters, pageNumber, rowsOfPage: 10 })
            .then((result) => {
                localStorage.setItem("statusIdTransaction", filters.idStatus);
                setData(result);
                if (result.length > 0) {
                    sessionStorage.setItem("rp_ActivePage_ChargeBack", pageNumber);
                    if (!sessionStorage.getItem("rp_filters_ChargeBack")) {
                        sessionStorage.setItem("rp_filters_ChargeBack", JSON.stringify(filters));
                    }
                    setTableData({
                        rows: result[0].maxRows,
                        pages: result[0].maxPages,
                        activePage: pageNumber,
                    });
                    setIsChargeBack(filters.idStatus == StatusEnum.ProcessOfAppeal);
                }
                else setTableData(initialTableData);
            });
    };

    const handleFilters = () => {
        sessionStorage.setItem("rp_filters_ChargeBack", JSON.stringify(filters));
        getTransactions(1);
    };

    const columns = getColumns(userSetting, handleFilters);

    const generateReport = () => {
        if (tableData.rows > 0) {
            chargebackService.generateReport({ ...filters, rows: tableData.rows });
        }
    };

    const handleSelectPage = (page) => {
        sessionStorage.setItem("rp_ActivePage", page);
        setTableData({ ...tableData, activePage: page });
        getTransactions(page);
    };

    const initCalendar = (filtersx) => {
        if (fechasApi.initDate && fechasApi.endDate && fechasApi.initDate.toString() !== "Invalid Date" && fechasApi.endDate.toString() !== "Invalid Date") {
            return (<CalendarWithEnabledRange
                startDate={fechasApi.initDate}
                endDate={fechasApi.endDate}
                defaultValue={[filtersx.initDate ? filtersx.initDate : fechasApi.endDate, filtersx.endDate ? filtersx.endDate : fechasApi.endDate]}
                onChange={(value) => {
                    var filterSession = JSON.parse(sessionStorage["rp_filters_ChargeBack"]);
                    filterSession.initDate = value[0];
                    filterSession.endDate = value[1];
                    sessionStorage.setItem("rp_filters_ChargeBack", JSON.stringify(filterSession));
                    return setFilters({
                        ...filters,
                        initDate: value[0],
                        endDate: value[1],
                    })
                }
                }
            />);
        } else {
            return (<Input readOnly value="No hay fechas a elegir." />);
        }
    }

    return (
        <section className="wrapper_transactions">
            <TitleSection title="Contracargos" />
            <div className="text-right">
                <Button
                    className="btn c_primary"
                    appearance="subtle"
                    type="submit"
                    onClick={generateReport}
                >
                    <Icon icon="file" />
                    <span className="fw-400"> Generar reporte</span>
                </Button>
            </div>
            <div className="box_filter_options">
                <Form onSubmit={handleFilters}>
                    <FlexboxGrid align="bottom">
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            md={6}
                            sm={12}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel>Buscar por rango de fechas</ControlLabel>
                                {initCalendar(filters)}
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            md={5}
                            sm={12}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel>Buscar por referencia</ControlLabel>
                                <InputGroup inside>
                                    <FormControl
                                        size="lg"
                                        name="input-1"
                                        placeholder="Referencia"
                                        onChange={(value) =>
                                            setFilters({ ...filters, referenceNumber: value })
                                        }
                                        value={filters.referenceNumber}
                                    />
                                    <InputGroup.Addon>
                                        <Icon icon="search" />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            md={5}
                            sm={12}
                            xs={24}
                        >
                            <FormGroup>
                                <ControlLabel>Buscar por correo electronico</ControlLabel>
                                <InputGroup inside>
                                    <FormControl
                                        size="lg"
                                        name="input-1"
                                        placeholder="Correo electronico"
                                        onChange={(value) =>
                                            setFilters({ ...filters, email: value })
                                        }
                                        value={filters.email}
                                    />
                                    <InputGroup.Addon>
                                        <Icon icon="search" />
                                    </InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                            className="mb-3"
                            componentClass={Col}
                            md={3}
                            sm={12}
                            xs={24}
                        >
                            <Button
                                className="btn secundary"
                                size="lg"
                                block
                                appearance="primary"
                                type="submit"                                
                            >
                                Buscar
                            </Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                    {isChargeBack ? (
                        <FlexboxGrid>
                            <FlexboxGrid.Item className="mb-6"
                                componentClass={Col}
                                md={6}
                                sm={12}
                                xs={24}>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="mb-5"
                                componentClass={Col}
                                md={5}
                                sm={12}
                                xs={24}
                                style={{ fontSize: 11 }}>
                                <CheckboxGroup inline name="chkStatusChargeback" value={filters.statusChargeBack} onChange={(value) => { setFilters({ ...filters, statusChargeBack: value }); }}>
                                    {statusChargeBack.map(item => {
                                        return <Checkbox key={`chk${item.idStatusChargeBack}`} value={item.idStatusChargeBack}>{item.description}</Checkbox>
                                    })}
                                </CheckboxGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>) : ''}
                </Form>
            </div>

            <div className="mt-3 mb-5">
                <br />
                <Table myData={data} myColumns={columns}></Table>
                <Pagination
                    prev
                    last
                    next
                    first
                    size="lg"
                    ellipsis
                    maxButtons={10}
                    boundaryLinks
                    pages={tableData.pages}
                    activePage={tableData.activePage}
                    onSelect={handleSelectPage}
                />
            </div>
        </section>
    );
};
